<template>
    <div>        
        <v-list flat v-if="termine.length > 0">
            <v-subheader>Deine Termine</v-subheader>
            <v-list-item-group >
                <v-list-item 
                    v-for="(item, index) in termine"
                    :key="index"
                    class="d-flex justify-space-between"
                    >
                        <div><span>{{item.tag}},  {{item.datum | date }} - {{item.zeit}} Uhr</span><br>
                            <span class="caption">{{item.studio}}</span></div>
                        <v-spacer/>
                        <span v-if="item.status==='abgesagt'">abgesagt</span>
                    
                        <v-btn outlined color="primary" @click="terminVorgangLoeschung(index)" v-else><v-icon
                           
                            color="primary"
                            
                        >
                            mdi-delete
                        </v-icon></v-btn>
                </v-list-item>
        </v-list-item-group>
       
        
        </v-list>
        
        <p v-else>Keine neuen Termine gebucht</p>
        
        <v-list flat v-if="alteTermine">
            <v-subheader>Vergangene Termine</v-subheader> 
               
            <v-list-item-group>
                <v-list-item
                    v-for="(item, index) in alteTermine"
                    :key="index"
                >               
                    <div>
                        <span>{{item.tag}},  {{item.datumRaw | date }} - {{item.zeit}} Uhr</span>
                        <span v-if="item.status==='abgesagt'"> (abgesagt)</span>
                        <br>
                        <span class="caption">{{item.studio}}</span>
                    </div>
                </v-list-item>
            </v-list-item-group>
        </v-list>
        
        <zurueck-button 
            class="my-3" 
            style="width: 100%;"
        />
        <snackbar :showSnack="snackbar.show" :snackText="snackbar.text" @closesnackbar="snackbar.show=false"/>
    </div>
</template>

<script>
import confirm from '../components/Promise.js'

export default {
    data() {
        return {
            termine: {},
            alteTermine: {},
            title: '',
            text: '',
            dialog: '',
            snackbar:{
                text: '',
                show: false
            }
        }
    },
    methods: {
        openTerminuebersicht(date) {
            this.$router.push(`/buchen/termin/${date}`)
        },

        getUserTermine() {
            let that = this;
            this.$axios.get('termine/getUserTermine')
            .then(
                response => {
                    this.termine = response.data.termine;
                    this.alteTermine = response.data.alteTermine;
                }
            )
            .catch(
                error => console.log(error)
            )
        },

        async frageLoeschen (index) {
            this.title = 'Termin löschen?';
            this.text = `Willst du den Termin am ${this.termine[index].tag}, ${this.$options.filters.date(this.termine[index].datum)} um ${this.termine[index].zeit} Uhr löschen?`;           
            this.dialog = true;
            return confirm(this.title, this.text, this.dialog).then(                
                result => result,
                error => error
            )
        },

        async frageKurzfristigLoeschen() {
            this.title = 'Kurzfristig Löschen?';
            this.text = `Der Termin ist nicht nicht kurzfristig stornierbar. Wenn du absagst, wird diese Einheit trotzdem gezählt`;
            this.dialog = true;
            return confirm(this.title, this.text, this.dialog).then(                
                result => result,
                error => error
            )
        },

        async deleteTermin(postData) {
            return this.$axios.post('termine/deleteUserTermin', postData)
            .then( response => response )
            .catch( error => error );
        },
        
        async terminVorgangLoeschung(index) {
            
            try {
                let sollLoeschen = await this.frageLoeschen(index);

                if(! sollLoeschen){
                    return;
                }

                const postData = {
                    'id':this.termine[index].id,
                    'forceKurzfristigStornieren': false,
                    'sender': 'Termine.vue'
                };

                this.spin = true;

                let response = await this.deleteTermin(postData);
                
                if (response.data.message==="Termin kurzfristig abgesagt."){
                    const kurzfristig = await this.frageKurzfristigLoeschen();

                    if(kurzfristig) {
                        postData.forceKurzfristigStornieren = true;

                        response = await this.deleteTermin(postData);
                    }
                }

                if(response.data.result==="success") {
                    this.termine.splice(index, 1);
                    this.snackbar.text = response.data.message;
                    this.snackbar.show = true;
                    this.getUserTermine();
                }                           

            } catch(error) {
                this.snackbar.text = error.message;
                this.snackbar.show = true;
            }    

            this.spin = false;
        },
        
    },

    mounted() {
        this.getUserTermine();
    }
}
</script>