import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        token: '',
        user: {
            nachname: null,
            vorname: null,
            email: null,
            nextTermin: null,
            id: null
        },
        config: {
            anzahlTageAnzeigen: null,
            anzahlWochenAnzeigen: null,
            maxTermineProWoche: null,
            minutenTerminAbsagen: null,
            minutenTermineEintragen: null,
            zeigeTermineLetztenTage: null,
            zeigeWochen: null
        },
        studios: null,
        homeStudio: null,
        version: process.env.VUE_APP_VERSION
    },
    getters: {
        getToken: (state) => {
            return state.token;
        },
        getUser: (state) => {
            return state.user;
        },
        getUserId: (state) => {
            return state.user.id;
        },
        getEmail: (state) => {
            return state.user.email;
        },
        getStudios: (state) => {
            return state.studios;
        },
        getHomeStudio: (state) => {
            return state.homeStudio;
        },
        getPartnerTermin: (state) => {
            return state.partnertermin;
        },
        getVersion: (state) => {
            return state.version;
        }
    },
    mutations: {
        setToken (state, token) {
          state.token = token;
        },
        setUser (state, data) {
            state.user.nachname = data.nachname,
            state.user.vorname = data.vorname,
            state.user.id = data.userID;
            state.user.email = data.username,
            state.user.nextTermin = data.nextTermin,
            state.studios = data.studios;
            state.homeStudio = data.homeStudio;
            state.partnertermin = data.partnertermin;
        },
        setHomeStudio(state, data) {
            state.homeStudio = data;
        },
        deleteUser(state) {
            state.user.nachname = null,
            state.user.vorname = null,
            state.user.id = null,
            state.user.email = null,
            state.user.nextTermin = null,
            state.token = null
        },
      }
});