<template>
<div>

<H3 class="mb-5">Hier findest du deine Tarifdetails</H3>

<v-card v-if="vertrag" class="mb-3">
    <v-list-item three-line>
        <v-list-item-content>
            <div class="text-overline mb-4">
                Vertrag
            </div>

            <v-list-item-title class="text-h5 mb-1">
                {{ vertrag.restEinheiten }} <span v-if="vertrag.restEinheiten == 1">Einheit</span>
                                            <span v-else>Einheiten</span>
            </v-list-item-title>

            <v-list-item-subtitle>Gebucht {{ vertrag.gebuchteEinheiten }} von {{ vertrag.anzahlEinheiten }} Einheiten</v-list-item-subtitle>
            <v-list-item-subtitle>Periode {{ vertrag.startPeriode | date }} - {{ vertrag.endePeriode |date }}</v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
</v-card>

    <v-card v-if="naechsteWoche.vertrag" class="mb-3">
        <v-list-item three-line>
            <v-list-item-content>
                <div class="text-overline mb-4">
                    Vertrag in der nächsten Woche
                </div>
                <v-list-item-title class="text-h5 mb-1">
                    {{ naechsteWoche.vertrag.restEinheiten }} <span v-if="naechsteWoche.vertrag.restEinheiten == 1">Einheit</span>
                                                <span v-else>Einheiten</span>
                </v-list-item-title>

                <v-list-item-subtitle>Gebucht {{ naechsteWoche.vertrag.gebuchteEinheiten }} von {{ naechsteWoche.vertrag.anzahlEinheiten }} Einheiten</v-list-item-subtitle>
                <v-list-item-subtitle>Periode {{ naechsteWoche.vertrag.startPeriode | date }} - {{ naechsteWoche.vertrag.endePeriode |date }}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-card>

    <v-card v-if="karte" class="mb-3">
        <v-list-item three-line>
            <v-list-item-content>
                <div class="text-overline mb-4">
                    Karte
                </div>

                <v-list-item-title class="text-h5 mb-1">
                    noch {{ karte.anzahlEinheiten }} Einheiten
                </v-list-item-title>

                <v-list-item-subtitle>Karte gültig bis {{ karte.laufzeit | date }}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-card>

    <v-card v-if="defaultKarte" class="mb-3">
        <v-list-item three-line>
            <v-list-item-content>
                <div class="text-overline mb-4">
                    aus einem weiteren Kontingent
                </div>

                <v-list-item-title class="text-h5 mb-1">
                    noch {{ defaultKarte }} <span v-if="defaultKarte == 1">Einheit</span>
                                            <span v-else>Einheiten</span>
                </v-list-item-title>

                <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-card>


<zurueck-button class="my-3" 
    style="width: 100%;"/>
</div>

</template>

<script>
export default {
    data() {
        return {
            vertrag: null,
            karte: null,
            defaultKarte: null,
            pause: {},
            naechsteWoche: null
        }
    },

    methods: {
        init() {
            let that = this;
            this.$axios.get('users/getTarifInfoDetails')
            .then(
                response => {
                    that.vertrag = response.data.vertrag;
                    that.karte = response.data.karte;
                    that.pause = response.data.pause;
                    that.defaultKarte = response.data.default;
                    that.naechsteWoche = response.data.naechsteWoche;
                }
            )
            .catch(
                error => console.log(error)
            )
        }
    },

    mounted() {
        this.init();
    }
    
}
</script>