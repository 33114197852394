<template>
    <v-app>
        <h3 >Hallo {{ user.vorname }} {{ user.nachname }}</h3>

        <p class="mt-10">{{ nextTermin }}</p>


        
        <v-btn
        color="primary"
        class="mt-5"
        @click="termineBuchen"
        >Termine buchen</v-btn>
        <v-btn
        color="primary"
        class="mt-5"
        @click="meineTermine"
        >Meine Termine</v-btn>
        <v-btn
        color="primary"
        class="mt-5"
        @click="tarifinfo"
        >Tarifinfo</v-btn>
         <v-btn
         class="mt-5"
         color="primary"
        @click="password"
        >Passwort ändern</v-btn>
        <v-btn
        color="primary"
        class="mt-5"
        @click="logOut"
        >LogOut</v-btn>       
    </v-app>
</template>

<script>
export default {
    data() {
        return {
            tarifInfoVertrag: null,
            tarifInfoKarte: null,
            nextTermin: null
        }
    },
    computed: {
        user() {
            return this.$store.getters.getUser
        }
    },
   methods: {
       init(){
           this.getUserInfo();
       },
       logOut() {
           this.$store.commit('deleteUser');
           this.$router.push('\login');
          /*let that = this;
           this.$axios.delete('login/1') 
           .then(
               response => {
                   this.$store.commit('deleteUser');
                   this.$router.push('\login');
               }
           )
           .catch( error => console.log(error))*/
       },
       meineTermine() {
           this.$router.push("/termine");
       },
       termineBuchen() {
           this.$router.push("/buchen/tage");
       },
       password(){
           this.$router.push("/password");
       },
       tarifinfo() {
           this.$router.push('/tarifinfo');
       },
       getUserInfo() {
           let that = this;
           this.$axios.get('users/getTarifInfo')
           .then(
               response => {
                   that.nextTermin = response.data.naechsterTermin;
               },          
               error => console.log(error)
           )
       }
       
   },
   mounted() {
       this.init();
   }
}
</script>

