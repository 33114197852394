import Vue from 'vue';
import Router from 'vue-router';
 
import isLoggedIn from './middleware/auth';

import Login from './modules/Login.vue'
import dash from './modules/Dash.vue';
import termine from './modules/Termine.vue';
import tage from './modules/Tage.vue';
import termin from './modules/Termin.vue';
import ForgotPassword from './modules/ForgotPassword.vue';
import Password from './modules/Password.vue';
import TarifInfo from './modules/TarifInfo.vue';

const routes = [
    { path: '/login', component: Login, name: 'login' },
    { path: '/password', component: Password, name: 'password' },
    { path: '/forgot-password', component: ForgotPassword, name: 'forgot-password' },
    { path: '/dash', component: dash, name: 'dash',  meta: {  requiresAuth: true } },
    { path: '/termine', component: termine, name: 'termine',  meta: {  requiresAuth: true } },
    { path: '/buchen/tage', component: tage, name: 'tage',  meta: {  requiresAuth: true } },
    { path: '/buchen/termin/:datum', component: termin, name: 'termin',  meta: {  requiresAuth: true } },
    { path: '/tarifinfo', component: TarifInfo, name: 'tarifinfo',  meta: {  requiresAuth: true } },
];

Vue.use(Router);

 const router = new Router({
   routes,
   mode: 'history',
 });

 router.beforeEach((to, from, next) => {
  
   if( isLoggedIn() ) {
     if(to.path==='/' || to.path==='/login'){
        next({ path: '/dash' })
     }     
   } else {
     if(to.matched.some(record => record.meta.requiresAuth) || to.path === '/'){
        next({ path: '/login' })
     }
    }   
    next();  
})


function nextFactory(context, middleware, index) {
 const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;