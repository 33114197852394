import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
//import Vuetify from 'vuetify/lib'; 
Vue.use(Vuetify)

const opts = {theme: {
    themes: {
      light: {
        primary: '#58EFCC',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c',
      },
    },
  },}

export default new Vuetify(opts)