<template>
    <v-btn 
     color="primary"
    @click="zurueck()">{{ text  }}
    </v-btn>
</template>

<script>
export default {
    props:{
        text:{
            default: 'Hauptmenü'
        }
    },
    methods: {
        zurueck() {
            this.$router.go(-1);
        }
    }
}
</script>