<template>
    <v-app>
        <v-main>        
            <v-row>
                <v-col 
                    cols="12"
                    sm="10"
                    md="8"
                    lg="5"
                    xl="4"
                    offset="0"
                    offset-sm="1"
                    offset-md="2"
                    offset-lg="3"
                    offset-xl="4">
                    <v-img class="mt-10 mb-20" src="./assets/logo-80.png"/>
         
                    <router-view class="pa-5 mt-16"/>                                       
                </v-col>
            </v-row>     
        </v-main>
        <v-footer app>
            <cookie-law>
                <div slot-scope="props" style="width:100%">
                    <div class="d-flex justify-end align-center">
                       <div> <span class="mr-4"> Diese Seite nutzt Cookies 🍪</span> </div>

                        <div><v-btn color="primary" @click="props.accept"><span>Verstanden</span></v-btn></div>
                    </div> 
                </div>            
            </cookie-law>

            <v-row>
                <v-col cols="2">
                </v-col>
                <v-col cols="8">
                    <v-layout class="d-flex justify-center">                
                        <span class="mr-4" target="_blank"><a :href="datenschutz">Datenschutz</a></span>
                        <span class="mr-4"><a :href="`https://${url}`" target="_blank">{{ url }}</a></span>
                        <span><a :href="impressum" target="_blank">Impressum</a></span>
                                  
                    </v-layout>
                </v-col>
                <v-col cols="2">
                    <v-layout class="d-flex justify-end align-end">
                        <span class="ml-4 t-version">{{ version }}</span>
                    </v-layout> 
                </v-col>
            </v-row>
            
        </v-footer>
    </v-app>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
export default {
    components: { CookieLaw },

    computed: {
        impressum() {
            return process.env.VUE_APP_IMPRESSUM_URL;
        },
        datenschutz() {
            return process.env.VUE_APP_Datenschutz_URL;
        },
        url() {
            if (process.env.VUE_APP_BACKEND_URL === "https://terminer.trainingsraum-hamburg.de/api/v1/") {
                return "";
            } else {
                return "www.pwbit.de";
            }
        },
        version() {
            return this.$store.getters.getVersion;
        }
    }  
}
</script>

<style lang="scss">
  @import "@/sass/variables.scss";
</style>