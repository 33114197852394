<template>
    <v-container>
        <p class="ml-4">Termine am {{ datumString }}</p>
        <v-row align="center">
            <v-col cols="4">
                <v-subheader>
                    In Studio
                </v-subheader>
            </v-col>
                
            <v-col cols="8">
                <v-select
                    ref="studioSelect"
                    item-text="name"
                    item-value="id"
                    v-model="studioDefaultItem"
                    :items="studioItems"
                    @change="getTermine()"
                    label="Select"
                    return-object
                    single-line
                    class="mr-5"
                />
            </v-col>
        </v-row>

        <v-list flat>  
                      
            <v-list-item v-if="termineInAnderenStudio.length > 0"> 
               <span class="caption" v-for="(item, index) in termineInAnderenStudio"
                :key="index">Du hast einen Termin an diesem Tag in Studio {{ item.studio }}</span>
            </v-list-item>
            <template v-if="termine.length > 0">
                <v-list-item v-for="(item, index) in termine"
                :key="`k-${index}`">
                    <v-btn
                        color="primary"
                        block
                        @click="getMethod(item, index)">
                        
                        <span :class="btnicon(item)">{{ item.uhrzeit }} Uhr</span>
                        <sup v-if="item.count > 1 && partnertermin === '1'">*</sup>
                        <span v-if="item.status === 'abgesagt' && item.hasTermin" class="ml-2">(abgesagt)</span>
                        <span v-else-if="item.hasTermin" class="material-icons ml-3">check</span>
                    </v-btn>                
                </v-list-item>
            </template>
             <v-list-item class="mt-6" v-else>In diesem Studio gibt es keine weiteren Termine an diesem Tag.</v-list-item>
            <v-list-item class="mt-3">
                <zurueck-button  text="zurück" style="width: 100%;"/>
            </v-list-item>
        </v-list>
        <span v-if="partnertermin === '1'" class="ml-8" style="font-weight: 400;font-size:12px;"><sup>*</sup> Partnertermin möglich</span>        
        <spinner v-if="spin"/>
        
        <snackbar :showSnack="snackbar.show" :snackText="snackbar.text" @closesnackbar="snackbar.show=false"/>    
    </v-container>
</template>

<script>

import confirm from '../components/Promise.js'

export default {
    data() {
        return {
            termine: {},
            datum: null,
            datumString: null,
            title: '',
            text: 'Wollen Sie den Termin buchen?',
            dialog: false,
            merkBuchenIndex: null,
            spin: true,
            snackbar: {
                text: '',
                show: false
            },
            errorMessage: 'Es ist ein Fehler aufgetreten. Bitte lade die Seite neu und versuche es noch einmal.',
            termineInAnderenStudio: null
        }
    },
    computed:{
        studioDefaultItem: {
            get() {
                return this.$store.getters.getHomeStudio;
            },
            set(value) {
                this.$store.commit('setHomeStudio', value)
            }            
        },
        studioItems() {
            return this.$store.getters.getStudios;        
        },
        partnertermin() {
            return this.$store.getters.getPartnerTermin;
        }
    },
    methods: {
        getMethod(item, index){
            if(item.status==="abgesagt"){
                this.snackbar.text = 'Dieser abgesagte Termin kann nicht neu gebucht werden.';
                this.snackbar.show = true;
            } else if(item.hasTermin){
                this.terminVorgangLoeschung(index)
            } else {
               this.buchungsVorgang(index);
            }
        },

        getTermine() {
            let that = this;
            this.spin = true;
            const post = { 'date': this.$route.params.datum, 'partnerTermine': false, 'studio': this.$store.getters.getHomeStudio.id };
            this.$axios.post('termine/getDay', post)
            .then(response => {
                that.termine = response.data.freieEinheiten
                that.termineInAnderenStudio = response.data.termineInAnderenStudio,
                that.datumString = response.data.datum
                })
            .catch(error => error)
            .finally(()=>that.spin=false)
        },

        async frageBuchen(index) {
            this.title = 'Termin buchen?';
            this.text = `Willst du den Termin am ${this.$options.filters.date(this.datum)} um ${this.termine[index].uhrzeit} Uhr buchen?`;
            this.merkBuchenIndex = index;
            this.dialog = true;
            return confirm( this.title, this.text, this.dialog).then(                
                result => result,
                error => error
            )
        },

        async frageKurzfristig() {
            this.title = 'Kurzfristig buchen?';
            this.text = `Die Buchung ist kurzfristig und kann dadurch nicht wieder storniert werden. Soll der Termin trotzdem gebucht werden?`;
            this.dialog = true;
            return confirm( this.title, this.text, this.dialog).then(                
                result => result,
                error => error
            )
        },

        async bucheTermin(postData) {
            return this.$axios.post('termine/bucheTermin', postData)
            .then( response => response )
            .catch( error => error );
        },
        
        async buchungsVorgang( index ) {
           try {
               // gibt es den Termin?
                if(!this.termine[index]) {
                    return;
                }

                // Frage nach, ob gebucht werden soll
                let sollBuchen = await this.frageBuchen(index);
                if(!sollBuchen){
                    return;
                }
                
                this.spin = true;  

                const postData = {
                    'datum' : this.datum,
                    'reihenId' : this.termine[index].reihenId,
                    'forceKurzfristigBuchen': false,
                    'studio': this.$store.getters.getHomeStudio.id
                };

                let response = await this.bucheTermin(postData);

                if(response.data.message==="kurzfristig") {
                    const kurzfristig = await this.frageKurzfristig();
                    if (kurzfristig){
                        postData.forceKurzfristigBuchen = true;
                        response = await this.bucheTermin(postData);
                    }
                } 

                if( response.data.result === "success" ) {
                    this.termine[index].hasTermin = true;
                    this.termine[index].id = response.data.terminId;     
                }  

                this.snackbar.text = response.data.message;
                this.snackbar.show = true;
           } catch(error) {
               this.snackbar.text = this.errorMessage;
               this.snackbar.show = true;
           }

           this.spin = false;
        },

        async frageLoeschen (index) {
            this.title = 'Termin löschen?';
            this.text = `Willst du den Termin am ${this.$options.filters.date(this.datum)} um ${this.termine[index].uhrzeit} Uhr löschen?`;           
            this.dialog = true;
            return confirm(this.title, this.text, this.dialog).then(                
                result => result,
                error => error
            )
        },

        async frageKurzfristigLoeschen() {
            this.title = 'Kurzfristig Löschen?';
            this.text = `Der Termin ist nicht nicht kurzfristig stornierbar. Wenn du absagst, wird diese Einheit trotzdem gezählt`;
            this.dialog = true;
            return confirm(this.title, this.text, this.dialog).then(                
                result => result,
                error => error
            )
        },

        async deleteTermin(postData) {
            return this.$axios.post('termine/deleteUserTermin', postData)
            .then( response => response )
            .catch( error => error );
        },
        
        async terminVorgangLoeschung(index) {
            
            try {
                let sollLoeschen = await this.frageLoeschen(index);

                if(! sollLoeschen){
                    return;
                }

                const postData = {
                    'id':this.termine[index].id,
                    'forceKurzfristigStornieren': false,
                    'sender': 'Termin.vue'
                };

                this.spin = true;

                let response = await this.deleteTermin(postData);
                
                if (response.data.message==="Termin kurzfristig abgesagt."){
                    const kurzfristig = await this.frageKurzfristigLoeschen();

                    if(kurzfristig) {
                        postData.forceKurzfristigStornieren = true;

                        response = await this.deleteTermin(postData);
                    }
                }

                if(response.data.result==="success") {
                    this.termine[index].hasTermin = false;
                    this.snackbar.text = response.data.message;
                    this.snackbar.show = true;
                }                           

            } catch(error) {
                this.snackbar.text = error.message;
                this.snackbar.show = true;
            }            
            this.spin = false;
        },
        
        btnicon(item){
            return (item.hasTermin)? 'ml-8': ''
        }
    },

    mounted() {
        this.getTermine();
        this.datum = this.$route.params.datum;
    }
}
</script>