import  store  from '../store/store.js';

export default function isLoggedIn() {  
  
    if (!store.getters.getToken) {
      return false;
    }

    return true; 
  
}