// axios
import myaxios from 'axios'
import Vue from 'vue';
import router from './router'
import store from './store/store';

const domain = ""

const axios = myaxios.create({
  domain
})

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;
axios.defaults.headers.common['Content-Type'] ='application/json;charset=utf-8';

axios.interceptors.request.use(
  (config) => {
    const token = store.getters.getToken; 
    if (token) {
        config.headers.common['X-TOKEN'] = token ;
    } else {
        config.headers.common['X-TOKEN'] = '';
    }

    return config;
  }, 

  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
    response => {
      if(response.data.error === "Authentication failed: Token invalid."){
        store.commit('deleteUser');
        router.push('\login');
      }
      return response
    },
    error => {
        if (error.response.status === 403 ){
            return router.push('/login');
        }
        
        return Promise.reject(error);
    }    
);

export default axios;
