<template>
    <v-container>
        <p class="ml-4">An welchem Tag möchtest du trainieren?</p>
        <v-row align="center">
            <v-col cols="4">
                <v-subheader>
          In Studio
        </v-subheader>
            </v-col>
                
            <v-col cols="8">
                <v-select
                    ref="studioSelect"
                    item-text="name"
                    item-value="id"
                    v-model="studioDefaultItem"
                    :items="studioItems"
                    @change="changeStudio()"
                    label="Select"
                    return-object
                    single-line
                    class="mr-5"
                />
            </v-col>
        </v-row>
        
        <v-list flat >
            <template v-if="tage.length > 0">
                <v-list-item
                v-for="(item, i) in tage"
                :key="i"
                >         
                    <v-btn @click="openTermineAnTag(item.dateRaw)"
                        color="primary"
                        style="width: 100%;">
                        <span :class="btnicon(item)">{{ item.datum }}</span>
                        <span v-if="item.hatTermin" class="material-icons ml-3">check</span>
                    </v-btn>
                </v-list-item>
            </template>
            
            <v-list-item class="mt-6" v-else>Es sind zur Zeit keine Trainingstage in diesem Studio freigegeben worden. Schau bitte später wieder vorbei!</v-list-item>
            
            <v-list-item class="mt-3">
                 <zurueck-button style="width: 100%"/>
            </v-list-item>
        </v-list>        
        
        <spinner v-if="spin"/>
        
        <snackbar :showSnack="snackbar.show" :snackText="snackbar.text" @closesnackbar="snackbar.show=false"/>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            tage: {},
            spin: true,
            snackbar: {
                text: '',
                show: false
            },
        }
    },
    computed:{
        studioDefaultItem: {
            get() {
                return this.$store.getters.getHomeStudio;
            },
            set(value) {
                this.$store.commit('setHomeStudio', value)
            }            
        },
        studioItems() {
            return this.$store.getters.getStudios;        
        },
    },
    methods: {        
        
        changeStudio() {            
            this.$nextTick(() => {
                this.$store.commit('setHomeStudio', JSON.parse(JSON.stringify(this.$refs.studioSelect.value)));
            }) 
            this.$nextTick(() => {
               this.getTage();
            })                     
        },

        getTage() {
            this.spin = true;
            let that = this;
            

             const id = this.$refs.studioSelect.value.id;
           
            this.$axios.get('termine/getDays/' + id)
            .then( response => { this.tage = response.data } )
            .catch( () =>  {
                that.snackbar.text = "Es ist ein Fehler aufgetreten. Bitte laden die Seite neu.";
                that.snackbar.show = true;
            } )
            .finally(() => that.spin = false)
        },
        openTermineAnTag( datum ) {
            this.$router.push(`termin/${datum}`);
        },
        btnicon(item){
            return (item.hatTermin)? 'ml-8': ''
        }
    },

    mounted() {
        this.$nextTick(() => {
          this.getTage();
         })
    }
}
</script>