<template>
    <v-form class="mt-16">
        <v-text-field
            v-model="username"    
            label="Username">
        </v-text-field>

        <v-text-field
            v-model="password"
            type="password"
            label="Kennwort"
            @keyup.enter="login()">
        </v-text-field>
        <br>
        <div class="d-flex justify-space-between">
            <v-btn
                color="primary"
                @click.prevent="login()"
                elevation="2"
                :loading="loading"
            >login
            </v-btn>

            <span class="mt-3">
                <router-link to="/forgot-password">Passwort vergessen?</router-link>
            </span>            
        </div>
        <snackbar :showSnack="snackbar.show" :snackText="snackbar.text" @closesnackbar="snackbar.show=false"/> 
    </v-form>
</template>

<script>
export default {
    data () {
        return{
            username: '',
            password: '',
            snackbar: {
                text: '',
                show: false
            },
            loading: false
        } 
    },
    methods: {
        login() {
            let that = this;
            const postData = { 'username': this.username, 'password': this.password }
            this.loading = true;
            this.$axios.post('login', postData )
            .then(
                response => {
                    if (response.data.result === 'error'){
                        this.loading = false;
                        that.setSnackbar(response.data.message)
                    } else {
                        that.$store.commit('setToken', response.data.token);
                        that.getUserData();
                    }                    
                },
                () => { 
                    this.loading = false;
                    that.setSnackbar("Es ist ein Fehler aufgetreten. Bitte lade die Seite neu und versuche es noch einmal");
                }
            )
        },
        getUserData() {
            let that = this;            
            this.$axios.get('users/getUser')
            .then(
                response => {
                   this.loading = false;
                   that.$store.commit('setUser', response.data);
                   that.$router.push("/dash")
                },
                () => {
                    this.loading = false;
                    that.setSnackbar("Es ist ein Fehler aufgetreten. Bitte lade die Seite neu und versuche es noch einmal");
                }
            )
        },
        setSnackbar(text){
            this.snackbar.text = text;
            this.snackbar.show = true;
        }
    }
}
</script>
