import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import axios from './axios.js';
import vuetify from './plugins/vuetify'
import router from './router.js';
import store from './store/store.js'; 
import moment from "moment";

import Zurueck from './components/Zurueck.vue'
import SnackBar from './components/Snackbar.vue';
import Spinner from './components/Spinner.vue';
import FadeLoader from 'vue-spinner/src/FadeLoader.vue';

Vue.component('zurueck-button', Zurueck);
Vue.component('spinner', Spinner);
Vue.component('snackbar', SnackBar);
Vue.component('fade-loader', FadeLoader);

Vue.filter('date', function (value) {
    moment.locale('de'); 
    return moment(value).format('L');
});

import App from './App.vue';

Vue.config.productionTip = false
Vue.prototype.$axios = axios;

var vue = new Vue({
    el: "#app",
    router,
    store,  
    vuetify,
    axios,
    render: h => h(App)
})