<template>
<div data-app>
    <v-dialog
      v-model="mydialog"
      persistent
      max-width="290"
    >
      
      <v-card>
        <v-card-title>
          {{title}}
        </v-card-title>
        <v-card-text>
          {{text}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="commit(true)"
          >
            Ja
          </v-btn>
          <v-btn
            text
            @click="commit(false)"
          >
            Nein
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
</template>

<script>
export default {

    props: {
        title: {
            required: true,
            type: String
        },
        text: {
            required: true,
            type: String
        },
        mydialog:{
          required: true,
            type: Boolean
        }
    },
    data() {
        return {
          sdfsd: null,
        }
    },
    methods: {
      commit(bool) {
        this.$emit('confirm', bool);
       
      }
    },
    mounted() {
     // console.log("mounted");
    }
}
</script>