import Dialog from "./Dialog.vue";
import Vue from 'vue';
import Vuetify from "vuetify";
Vue.use(Vuetify);

export default function confirm(title, text, mydialog) {
  return new Promise((resolve, reject) => {
    
    
    const dialog = new Vue({
      methods: {
        closeHandler(fn, arg) {
          dialog.$destroy();
          dialog.$el.remove(); 
          return function() {            
            fn(arg);    
          };
        }
      },
      vuetify: new Vuetify(), 
      render(h) {
        return h(Dialog, {
          props: {
            title,
            text,
            mydialog
          },
          on: {
            confirm: (value) => {  this.closeHandler(resolve(value)) },
            cancel: () => {  this.closeHandler(reject, new Error('canceled')) }
          }
        });
      }
    }).$mount();
    document.body.appendChild(dialog.$el);
  });


}