<template>
    <v-form ref="form">
        <h5>Ändere hier dein Passwort</h5>
        <v-text-field
        label="altes Passwort"
        v-model="passwordOld"
        :rules="passwordOldRules">        
        </v-text-field>
        <v-text-field
        label="neues Passwort"
        v-model="passwordNew"
        :rules="passwordNewRules">
        </v-text-field>
        <v-text-field
        label="neues Passwort bestätigen"
        v-model="passwordConfirm"
        :rules="passwordConfirmRules">>
        >
        </v-text-field>
        <div class="d-flex justify-space-between">
        <v-btn
        @click="changePassword"
        color="primary">Speichern
        </v-btn>
        <v-spacer/>
        <v-btn @click="$router.push('/dash')">zurück</v-btn>
        <snack-bar :showSnack="snackbar.show" :snackText="snackbar.text"/>
        </div>
    </v-form>
</template>

<script>
import SnackBar from '../components/Snackbar.vue'
export default {
    components:{
        SnackBar
    },
    data() {
        return {
            passwordOld: '',
            passwordOldRules: [
                (v) => !!v || "Bitte das alte Passwort eingeben.",
            ],
            passwordNewRules: [
                (v) => !!v || "Bitte das alte Passwort eingeben.",
                (v) => v.length > 7 || "Mindestens 8 Zeichen"
            ],
            passwordNew: '',
            passwordConfirm: '',
            passwordConfirmRules: [
                (v) => !!v || "Bitte das neue Passwort bestätigen.",
                (v) => v.length > 7 || "Mindestens 8 Zeichen",
                (v) => v == this.passwordNew || "Passwörter sind unterschiedlich"
            ],
            snackbar: {
                text: '',
                show: false
            },
        }
    },
    methods: {
        changePassword() {
            if(!this.$refs.form.validate()){
                return;
            }
            let that = this;
            const payload = { 'username': that.$store.getters.getEmail, 'oldpassword': this.passwordOld, 'newpassword': this.passwordNew};
            this.$axios.post( 'password/' + encodeURIComponent(payload.username), payload )
            .then(
                response => {               
                    that.snackbar.text = response.data.message;
                    that.snackbar.show = true;
                },
                error => { 
                    that.snackbar.text = error.data.message;
                    that.snackbar.show = true;
                }
            )
        }
    }
}
</script>