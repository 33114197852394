<template>
  <div class="text-center ma-2">    
    <v-snackbar
      v-model="show"
      timeout="2000">
      {{ snackText }}      
    </v-snackbar>
  </div>
</template>

<script>
export default {
    props: {
        showSnack: {         
          required: true
        },
        snackText: {         
          required: true
        }
    },
    computed: {
      show: {
        get() { return this.showSnack },
        set() { this.$emit("closesnackbar"); }
      }
    }
}
</script>