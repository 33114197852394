<template>
    <div>
        <h2 class="mt-5">{{ title }}</h2>
        <p class="mt-10">{{ text }}</p>
        <v-form 
            ref="form" 
            lazy-validation>
            <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-Mail-Adresse"/>

            <div class="d-flex justify-space-between">
                <v-btn
                    color="primary"
                    @click="newPassword"
                    :disabled="submitBtnDisabled">
                Neues Passwort anfordern</v-btn>
                <span class="mt-3">
                    <router-link to="/login">Zum Login</router-link>
                </span>
            </div>
        </v-form>
        <snackbar :showSnack="snackbar.show" 
                :snackText="snackbar.text"
                 @closesnackbar="snackbar.show=false"/>               
        <spinner v-if="spin"/>
    </div>
</template>

<script>
export default {
    data () {
        return {
            title: 'Du hast dein Passwort vergessen?',
            text: 'Wir schicken dir ein neues Passwort an deine E-Mail-Adresse. ' 
            + 'Solltest du keine E-Mail bekommen, überprüfe bitte, ob du die richtige Adresse angegeben hast.',
            email: '',
            emailRules: [
                (v) => !!v || "Bitte eine E-Mail-Adresse angeben",
                (v) => /.+@.+\..+/.test(v) || "Bitte E-Mail-Adresse prüfen",
            ],
            snackbar: {
                show: false,
                text: ''
            },
            spin: false,
            submitBtnDisabled: false,
        }
    },
    methods: {
        newPassword() {
            if(!this.$refs.form.validate()){
                return;
            }
            let that = this;
            this.spin = true;

            this.$axios.get('password/' + encodeURIComponent(this.email) )
            .then(
                response => {
                    if(response.data.result === 'success'){
                        that.submitBtnDisabled = true;   
                    }
                    that.snackbar.text = response.data.message;
                    that.snackbar.show = true;
                },
                error => {
                    that.snackbarText = error.data.message;
                    that.snackbar = true;
                }
            )
            .finally(
                () => that.spin = false
            )
        }
    }
}
</script>