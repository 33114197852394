<template>    
    <v-overlay :value="overlay"><fade-loader :loading="loading" color="lightgrey"/></v-overlay>
</template>

<script>
export default {
    data() {
        return {
            overlay: true,
            loading: true,
        }
    }
}
</script>